<template>
  <div>
    <!--Preloader area start here-->
    <div id="loader" class="loader" v-if="isLoading = true">
      <div class="loader-container"></div>
    </div>
    <!--Preloader area End here-->
    <navbar></navbar>
    <router-view/>
    <MyFooter></MyFooter>
    <!-- start scrollUp  -->
    <div id="scrollUp" class="orange-color">
      <i class="fa fa-angle-up"></i>
    </div>
    <!-- End scrollUp  -->

    <!-- Search Modal Start -->
    <div aria-hidden="true" class="modal fade search-modal" role="dialog" tabindex="-1">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span class="flaticon-cross"></span>
      </button>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="search-block clearfix">
            <form>
              <div class="form-group">
                <input class="form-control" placeholder="Search Here..." type="text">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Search Modal End -->
  </div>
</template>

<script>
  import Navbar from "@/views/Navbar";
  import MyFooter from "@/views/MyFooter";
  export default {
    name: "App",
    data () {
      return {
          isLoading: true
      }
    },
    components: {
      Navbar,
      MyFooter
    },
    mounted() {
      document.onreadystatechange = function () {
        if (document.readyState === 'complete') {
          console.log('files are loaded completely')
          this.isLoading = false
        }else {
          console.log('loading...')
          this.isLoading = true
        }
      }
    }
  }
</script>
