import Vue from 'vue'
import App from './App.vue'
import router from './router'
import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);
//template css import
import './assets/css/bootstrap.min.css'
import './assets/css/font-awesome.min.css'
import './assets/fonts/flaticon.css'
import './assets/css/animate.css'
import './assets/css/owl.carousel.css'
import './assets/css/slick.css'
import './assets/css/off-canvas.css'
import './assets/css/magnific-popup.css'
import './assets/css/rsmenu-main.css'
import './assets/css/rs-spacing.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/scss/app.scss'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted() {
    require('./assets/js/main')
  }
}).$mount('#app')


