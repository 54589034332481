<template>
    <div id="rs-contact" class="rs-contact gray-color pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="contact-box">
                            <h2 class="title white-color">Success!</h2>
                            <h3 class="white-color">Thank you for contacting us!</h3>
                            <p class="white-color">We will get back to you shortly!</p>
                            <small class="white-color">You will be redirect back to home page in 10 seconds!</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactUsSuccess",
        mounted() {
                setTimeout( ()=> this.$router.push({name: 'Home'}), 10000);
        }
    }
</script>
