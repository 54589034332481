<template>
    <div class="rs-video-wrap style2 inner pb-80 md-pb-50">
        <div class="container">
            <div class="row margin-0 gray-color">
                <div class="col-lg-12 padding-0">
                    <div class="rs-requset">
                        <form id="join-us" name="join-us" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" @submit.prevent="handleSubmit">
                            <input type="hidden" name="form-name" value="join-us">
                            <fieldset>
                                <div class="row">
                                    <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                        <input class="form-control" type="text" id="name" name="name" v-model="form.contact_name" placeholder="Name" required="">
                                    </div>
                                    <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                        <input class="form-control" type="number" id="phone" name="phone" v-model="form.contact_phone" placeholder="Phone Number" required="">
                                    </div>
                                    <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                        <input class="form-control" type="email" id="email" name="email" v-model="form.contact_email" placeholder="E-Mail" required="">
                                    </div>
                                    <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                        <input class="form-control" type="text" id="job_position" name="job_position" v-model="form.contact_job_position" placeholder="Job Position" required="">
                                    </div>
                                    <div class="col-lg-6 mb-30 col-md-6 col-sm-6">
                                        <input class="form-control" type="text" id="resume_link" name="resume_link" v-model="form.contact_resume_link" placeholder="Resume Link" required="">
                                    </div>
                                    <div class="col-lg-12 mb-45">
                                        <textarea class="form-control" id="message" name="message" v-model="form.contact_message" placeholder="Tell us more about you" required=""></textarea>
                                    </div>
                                </div>
                                <div class="btn-part">
                                    <div class="form-group mb-0">
                                        <input class="readon learn-more submit" type="submit" value="Submit Now">
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "JoinUsForm",

        data () {
            return {
                form: {
                    contact_name: '',
                    contact_email: '',
                    contact_phone: '',
                    contact_job_position: '',
                    contact_resume_link: '',
                    contact_message: ''
                },
                form_success: false,
                form_fail: false
            }
        },
        methods: {
            encode (data) {
                return Object.keys(data)
                    .map(
                        key=> `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
                    )
                    .join("&");
            },
            checkForm() {
                if(this.form.contact_name === '') {
                    return false
                }else if(this.form.contact_email === '') {
                    return false
                }else if(this.form.contact_phone === '') {
                    return false
                }else if(this.form.contact_job_position === ''){
                    return false
                }else if(this.form.contact_resume_link === '') {
                    return false
                }else if(this.form.contact_message === ''){
                    return false
                }else {
                    return true
                }
            },
            handleSubmit(){
                if(this.checkForm()) {
                    const axiosConfig = {
                        header: { "ContentType": "application/x-www-form-urlencoded"}
                    };

                    axios.post(
                        "/",
                        this.encode({
                            "form-name": "join-us",
                            ...this.form
                        }),
                        axiosConfig
                    ).then(()=>{
                        this.form_success = true
                    }).catch(()=>{
                        this.form_fail = true
                    });

            }
          }
        }
    }
</script>
