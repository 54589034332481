import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from "../views/About.vue";
import SystemDevelopment from "../views/services/SystemDevelopment.vue";
import WebDesign from "../views/services/WebDesign";
import SocialMediaMarketing from "../views/services/SocialMediaMarketing";
import Blog from "../views/Blog";
import Portfolio from "../views/Portfolio";
import Contact from "../views/Contact";
import ContactUsSuccess from "../views/partials/ContactUsSuccess";
import ContactUsError from "../views/partials/ContactUsError";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    force: true
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    force: true
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio,
    force: true
  },
  {
    path: '/our-blog',
    name: 'Blog',
    component: Blog,
    force: true
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact,
    force: true
  },
  {
    path: '/services/system-development',
    name: 'SystemDevelopment',
    component: SystemDevelopment,
    force: true
  },
  {
    path: '/services/web-design',
    name: 'WebDesign',
    component: WebDesign,
    force: true
  },
  {
    path: '/services/social-media-marketing',
    name: 'SocialMediaMarketing',
    component: SocialMediaMarketing,
    force: true
  },
  {
    path: '/contact-us/success',
    name: 'ContactUsSuccess',
    component: ContactUsSuccess,
    force: true
  },
  {
    path: '/contact-us/error',
    name: 'ContactUsError',
    component: ContactUsError,
    force: true
  }
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
  mode: 'history'
})


router.afterEach(() => {
})

export default router
