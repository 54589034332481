<template>
  <div id="portfolio">
    <!-- Project Section Start -->
    <div class="rs-project style6 modify1 pt-60 pb-20 md-pt-40 md-pb-20">
      <div class="container">
        <div class="row">
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/tefal.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://clubtefal.com.my/" target="_blank">Tefal Malaysia</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/gatsby.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://gatsby.com.my/" target="_blank">Gatsby</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 md-mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/faustino.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://faustino-msia.com/" target="_blank">Faustino</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/icommunity.jpg" alt="images">
               <!-- <div class="plus-icon"><a href=#> target=_blank<i class="fa fa-plus"></i></a></div>-->
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://icommunity.my/" target="_blank">iCOMMUNITY</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/ETAG.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://etag.com.my/" target="_blank">Ernest Tham Advisory Group (ETAG)</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/DNMAdvisory.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://quote.dnmadvisory.com/" target="_blank">DNMAdvisory</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img pb-7">
                <img src="~@/assets/images/portfolio/capsphere.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://www.capsphere.com.my/" target="_blank">Capsphere</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/bloomsnature.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://bloomsnature.com/" target="_blank">Bloom Nature</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/Gokayu.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://gokayu.com" target="_blank">Gokayu</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/nbsupply.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://nb-food.com/" target="_blank">NB Foods</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/goodvirtues.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://goodvirtuesco.com/" target="_blank">Good Virtues</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/pixy.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://pixy.com.my/" target="_blank">Pixy</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/tulique.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://www.tuliqueclinic.com/" target="_blank">Tulique</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/JSPwellness.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="http://www.jspwellness-bonealignment.com/" target="_blank">JSP Wellness</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/homeplus.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://www.homeplus2u.com/" target="_blank">Home Plus</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/wineexpress.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://wineexpress2u.com/" target="_blank">Wine Express</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/medixtra.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="http://medixtra-bpk.com/" target="_blank">Megah Bina (mediXtra)</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-40">
            <div class="project-item">
              <div class="project-img">
                <img src="~@/assets/images/portfolio/PAAB.jpg" alt="images">
              </div>
              <div class="project-content">
                <h3 class="title"><a href="https://www.paab.my/" target="_blank">Pengurusan Aset Air Berhad</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Project Section End -->
  </div>
</template>

<script>
export default {
name: "Portfolio"
}
</script>

<style scoped>

</style>
