<template>
    <form id="home-contact-us" action="/contact-us/success" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" @submit.prevent="onSubmit">
        <input type="hidden" name="form-name" value="home-contact-us">
        <fieldset>
            <div class="row">
                <div class="col-lg-12 mb-15">
                    <input class="from-control" type="text" id="name" name="name" v-model="form.name" placeholder="Name" required="">
                </div>
                <div class="col-lg-12 mb-15">
                    <input class="from-control" type="email" id="email" name="email" v-model="form.email" placeholder="E-Mail" required="">
                </div>
                <div class="col-lg-12 mb-15">
                    <input class="from-control" type="number" id="phone" name="phone" v-model="form.phone" placeholder="Phone Number" required="">
                </div>
                <div class="col-lg-12 mb-25">
                    <input class="from-control" type="text" id="Website" name="subject" v-model="form.website_name" placeholder="Your Website" required="">
                </div>
            </div>
            <div class="form-group mb-0">
                <input class="submit-btn" type="submit" value="Submit Now">
            </div>
        </fieldset>
    </form>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "HomeContactForm",
        data () {
            return {
                form: {
                    name: '',
                    phone: '',
                    email: '',
                    website_name: ''
                },
                form_success: false,
                form_fail: false
            }
        },
        methods:{
            encode (data) {
                return Object.keys(data)
                    .map(
                        key=> `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
                    )
                    .join("&");
            },
            checkForm() {
                if(this.form.name === '') {
                    return false
                }else if(this.form.email === '') {
                    return false
                }else if(this.form.phone === '') {
                    return false
                }else if(this.form.website_name === ''){
                    return false
                }else {
                    return true
                }
            },
            onSubmit(){
                if(this.checkForm()){

                    const axiosConfig = {
                        header: { "ContentType": "application/x-www-form-urlencoded"}
                    };

                    axios.post(
                        "/",
                        this.encode({
                            "form-name": "home-contact-us",
                            ...this.form
                        }),
                        axiosConfig
                    ).then(()=>{
                        this.form_success = true
                        this.$router.push({name:'ContactUsSuccess'})
                    }).catch(()=>{
                        this.form_fail = true
                        this.$router.push({name:'ContactUsFail'})
                    });
                }
            }
        }
    }

</script>
