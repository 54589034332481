<template>
  <div id="about">
    <!-- Tagline -->
<!--    <div class="tagline">
      <div class="container pt-120 pb-120">
        <div class="row">
          <div class="col-12">
            <h1 class="title wow fadeInDown">Website <span style="color: #1273eb;">help you</span><br> promote 24/7</h1>
            <div class="line"></div>
            <div class="row">
              <div class="col-lg-10 col-md-12">
                <h4 class="mt-80 subtitle wow fadeInLeft" data-wow-delay="0.5s">We are professional in providing <span style="color: #1273eb;">IT and digital solutions</span> to all range of businesses, and striving to achieve
                  recognition in ASEAN by year 2025.</h4>
              </div>
              <div class="col-lg-2 col-md-12 hidden-md">

              </div>
            </div>

            <div class="row">
              <div class="col-lg-2 col-md-12 hidden-md">

              </div>
              <div class="col-lg-10 col-md-12">
                <h4 class="mt-80 text-left subtitle-2 wow fadeInLeft" data-wow-delay="0.8s">We strive to deliver measurable outcomes to our customers. By using modern technologies, within quality, speed and security.</h4>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>-->
    <!-- Tagline End -->

    <!-- About Section Start -->
    <div class="rs-about gray-color pt-60 pb-100 md-pt-40 md-pb-50">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 md-mb-30">
            <div class="rs-animation-shape">
              <div class="images">
                <img src="~@/assets/images/new/about-us.png" alt="">
              </div>
              <div class="middle-image2">
                <img class="dance3" src="~@/assets/images/about/effect-1.png" alt="">
              </div>
            </div>
          </div>
          <div class="col-lg-6 pl-60 md-pl-15">
            <div class="contact-wrap">
              <div class="sec-title mb-30">
                <div class="sub-text style-bg wow fadeInDown">About Us</div>
                <h2 class="title pb-38 wow fadeInRight" data-wow-delay="0.8s">
                  We are lean, modern technological solutions provider.
                </h2>
                <div class="desc pb-35 wow fadeInUp">
                  <p>Over 5 years we had provided services to a wide range of customers, from startups, SMEs to large corporations and GLCs.</p>
                </div>
                <p class="margin-0 pb-15">
                  We began our business journey back in 2015, started by a two university students. With their joint focus and effort, they grew Nedex Group to what it is today,
                  had achieved recognitions from our customers and general public, with the growth, a corporate office was set up in SS15, Subang Jaya in year 2019.
                 <!-- After some time, Nedex Group started to have few close friends to join the company, with all of them working in just a small shoplot room.
                  Finally after few years with more and more people get to know Nedex Group, the corporate office was set up in SS15 on 2019.
                  Past few years are not easy, but we always strive hard to work the best to our clients without compromising the quality of the work.-->
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="shape-image">
          <img class="top dance" src="~@/assets/images/about/dotted-3.png" alt="">
        </div>
      </div>
    </div>
    <!-- About Section End -->

    <!-- Technology Section Start -->
    <div class="rs-technology style2 bg11 pt-55 pb-80 md-pt-40 md-pb-40">
      <div class="container">
        <div class="sec-title2 text-center mb-45">
          <span class="sub-text white-color">Technology Index</span>
          <h2 class="title title2 white-color">
            Our Tech Stack
          </h2>
          <p class="sub-text white-color">We use the best and most stable Techs for our customers.</p>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-6 md-pb-30">
            <div class="technology-wrap">
              <div class="rs-grid-figure">
                <div class="logo-img">
                  <a target="_blank" href="https://laravel.com/#">
                    <img class="hover-img" src="~@/assets/images/new/tech/laravel.png" alt="hover-image">
                    <img class="main-img" src="~@/assets/images/new/tech/laravel-white.png" alt="hover-image">
                  </a>
                </div>
              </div>
              <div class="logo-title">
                <h4 class="title">Laravel</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 md-pb-30">
            <div class="technology-wrap">
              <div class="rs-grid-figure">
                <div class="logo-img">
                  <a target="_blank" href="https://vuejs.org/">
                    <img class="hover-img" src="~@/assets/images/new/tech/vuejs.png" alt="grid-image">
                    <img class="main-img" src="~@/assets/images/new/tech/vuejs-white.png" alt="grid-image">
                  </a>
                </div>
              </div>
              <div class="logo-title">
                <h4 class="title">Vue JS</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 md-pb-30">
            <div class="technology-wrap">
              <div class="rs-grid-figure">
                <div class="logo-img">
                  <a target="_blank" href="https://aws.amazon.com/">
                    <img class="hover-img" src="~@/assets/images/new/tech/aws.png" alt="grid-image">
                    <img class="main-img" src="~@/assets/images/new/tech/aws-white.png" alt="grid-image">
                  </a>
                </div>
              </div>
              <div class="logo-title">
                <h4 class="title">AWS</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 sm-pb-30">
            <div class="technology-wrap">
              <div class="rs-grid-figure">
                <div class="logo-img">
                  <a target="_blank" href="https://flutter.dev/">
                    <img class="hover-img" src="~@/assets/images/new/tech/flutter.png" alt="grid-image">
                    <img class="main-img" src="~@/assets/images/new/tech/flutter-white.png" alt="grid-image">
                  </a>
                </div>
              </div>
              <div class="logo-title">
                <h4 class="title">Flutter</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Technology Section End -->

    <!-- Counter Section Start -->
    <div class="rs-contact-wrap bg5 pt-40 pb-390 md-pt-80">
      <div class="container">
        <div class="sec-title2 text-center mb-30">
         <!-- <span class="sub-text style-bg white-color">Looking for an opportunity to build a career in digital sphere?</span>-->
          <h2 class="title white-color pb-15">
            Grow With Us
          </h2>
       <!--   <p class="white-color">We are always welcome those who are full of passion and enthusiam.
                                At Nedex, you are able to get personal growth and career advancement from the very best.
                                Are you looking for an opportunity on building career in the digital sphere?
                                No matter fresh graduate or an experienced professional welcome to apply.</p>-->
          <p class="white-color">Looking for an opportunity to build a career in digital sphere?</p>
          <p class="white-color">We always welcome those who are full of passion and enthusiasm!</p>
          <p class="white-color">No matter fresh graduate or an experienced professional, you are most welcome to apply!</p>
        </div>
      </div>
    </div>
    <!-- Counter Section End -->

    <!-- Video Section End -->
    <!--Join us form starts here -->
    <!--Join us form has been refractor to a single template forms-->
    <!--Can be find in src->views->partials -->
    <JoinUsForm></JoinUsForm>
    <!--Join us form ends here -->
    <!-- Video Section End -->
  </div>
</template>
<script>
  import axios from 'axios';
  import JoinUsForm from "@/views/partials/JoinUsForm";
  export default {
    name: 'About',
    components: {JoinUsForm},
    mounted() {
      $(document).ready(function () {

        //custom js
        $('.tagline .title::after').css('width', '25%');

        //custom js end

        // sticky menu
        var header = $('.menu-sticky');
        var win = $(window);

        win.on('scroll', function() {
          var scroll = win.scrollTop();
          if (scroll < 1) {
            header.removeClass("sticky");
          } else {
            header.addClass("sticky");
          }

          $("section").each(function() {
            var elementTop = $(this).offset().top - $('#rs-header').outerHeight();
            if(scroll >= elementTop) {
              $(this).addClass('loaded');
            }
          });

        });

        //window load
        $(window).on( 'load', function() {
          $("#loading").delay(1500).fadeOut(500);
          $("#loading-center").on( 'click', function() {
            $("#loading").fadeOut(500);
          })
        })

        // Parallax Stuff
        if ($("#stuff").length) {
          var stuff = $('#stuff').get(0);
          var parallaxInstance = new Parallax(stuff);
        }

        // onepage nav
        var navclose = $('#onepage-menu');
        if(navclose.length){
          $(".nav-menu li a").on("click", function () {
            if ($(".showhide").is(":visible")) {
              $(".showhide").trigger("click");
            }
          });

          if ($.fn.onePageNav) {
            $(".nav-menu").onePageNav({
              currentClass: "current-menu-item"
            });
          }
        }

        //Testimonials Slider
        var sliderfor = $('.slider-for');
        if(sliderfor.length){
          $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav',
            autoplay: true
          });
        }
        var slidernav = $('.slider-nav');
        if(slidernav.length){
          $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            centerMode: true,
            focusOnSelect: true,
          });
        }

        // collapse hidden
        var navMain = $(".navbar-collapse");
        navMain.on("click", "a:not([data-toggle])", null, function () {
          navMain.collapse('hide');
        });

        // video
        if ($('.player').length) {
          $(".player").YTPlayer();
        }

        // wow init
        new WOW().init();

        // image loaded portfolio init
        var gridfilter = $('.grid');
        if(gridfilter.length){
          $('.grid').imagesLoaded(function() {
            $('.gridFilter').on('click', 'button', function() {
              var filterValue = $(this).attr('data-filter');
              $grid.isotope({
                filter: filterValue
              });
            });
            var $grid = $('.grid').isotope({
              itemSelector: '.grid-item',
              percentPosition: true,
              masonry: {
                columnWidth: '.grid-item',
              }
            });
          });
        }

        // project Filter
        if ($('.gridFilter button').length) {
          var projectfiler = $('.gridFilter button');
          if(projectfiler.length){
            $('.gridFilter button').on('click', function(event) {
              $(this).siblings('.active').removeClass('active');
              $(this).addClass('active');
              event.preventDefault();
            });
          }
        }

        // magnificPopup init
        var imagepopup = $('.image-popup');
        if(imagepopup.length){
          $('.image-popup').magnificPopup({
            type: 'image',
            callbacks: {
              beforeOpen: function() {
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure animated zoomInDown');
              }
            },
            gallery: {
              enabled: true
            }
          });
        }

        // Get a quote popup
        var popupquote = $('.popup-quote');
        if(popupquote.length){
          $('.popup-quote').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#qname',
            removalDelay: 500,
            callbacks: {
              beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
                if(win.width() < 800) {
                  this.st.focus = false;
                } else {
                  this.st.focus = '#qname';
                }
              }
            }
          });
        }

        //preloader
        $(window).on('load', function() {
          $("#loader").delay(1000).fadeOut(500);
        })

        //Videos popup jQuery
        var popupvideos = $('.popup-videos');
        if(popupvideos.length){
          $('.popup-videos').magnificPopup({
            disableOn: 10,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
          });
        }

        //CountDown Timer
        var CountTimer = $('.CountDownTimer');
        if(CountTimer.length){
          $(".CountDownTimer").TimeCircles({
            fg_width: 0.030,
            bg_width: 0.8,
            circle_bg_color: "#eeeeee",
            circle_fg_color: "#eeeeee",
            time: {
              Days:{
                color: "#032390"
              },
              Hours:{
                color: "#032390"
              },
              Minutes:{
                color: "#032390"
              },
              Seconds:{
                color: "#032390"
              }
            }
          });
        }

        var pieChart = $('.progress-pie-part');
        if(pieChart.length) {
          $('.chart').easyPieChart({
            size: 172,
            barColor: "#032390",
            scaleLength: 0,
            lineWidth: 8,
            trackColor: "#eeeeee",
            lineCap: "circle",
            animate: 2000,
          });
        }

        /*-------------------------------------
            OwlCarousel
        -------------------------------------*/
        $('.rs-carousel').each(function() {
          var owlCarousel = $(this),
              loop = owlCarousel.data('loop'),
              items = owlCarousel.data('items'),
              margin = owlCarousel.data('margin'),
              stagePadding = owlCarousel.data('stage-padding'),
              autoplay = owlCarousel.data('autoplay'),
              autoplayTimeout = owlCarousel.data('autoplay-timeout'),
              smartSpeed = owlCarousel.data('smart-speed'),
              dots = owlCarousel.data('dots'),
              nav = owlCarousel.data('nav'),
              navSpeed = owlCarousel.data('nav-speed'),
              xsDevice = owlCarousel.data('mobile-device'),
              xsDeviceNav = owlCarousel.data('mobile-device-nav'),
              xsDeviceDots = owlCarousel.data('mobile-device-dots'),
              smDevice = owlCarousel.data('ipad-device'),
              smDeviceNav = owlCarousel.data('ipad-device-nav'),
              smDeviceDots = owlCarousel.data('ipad-device-dots'),
              smDevice2 = owlCarousel.data('ipad-device2'),
              smDeviceNav2 = owlCarousel.data('ipad-device-nav2'),
              smDeviceDots2 = owlCarousel.data('ipad-device-dots2'),
              mdDevice = owlCarousel.data('md-device'),
              centerMode = owlCarousel.data('center-mode'),
              HoverPause = owlCarousel.data('hoverpause'),
              mdDeviceNav = owlCarousel.data('md-device-nav'),
              mdDeviceDots = owlCarousel.data('md-device-dots');
          owlCarousel.owlCarousel({
            loop: (loop ? true : false),
            items: (items ? items : 4),
            lazyLoad: true,
            center: (centerMode ? true : false),
            autoplayHoverPause: (HoverPause ? true : false),
            margin: (margin ? margin : 0),
            //stagePadding: (stagePadding ? stagePadding : 0),
            autoplay: (autoplay ? true : false),
            autoplayTimeout: (autoplayTimeout ? autoplayTimeout : 1000),
            smartSpeed: (smartSpeed ? smartSpeed : 250),
            dots: (dots ? true : false),
            nav: (nav ? true : false),
            navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
            navSpeed: (navSpeed ? true : false),
            responsiveClass: true,
            responsive: {
              0: {
                items: (xsDevice ? xsDevice : 1),
                nav: (xsDeviceNav ? true : false),
                dots: (xsDeviceDots ? true : false),
                center: false,
              },
              768: {
                items: (smDevice2 ? smDevice2 : 2),
                nav: (smDeviceNav2 ? true : false),
                dots: (smDeviceDots2 ? true : false),
                center: false,
              },
              992: {
                items: (smDevice ? smDevice : 3),
                nav: (smDeviceNav ? true : false),
                dots: (smDeviceDots ? true : false),
                center: false,
              },
              1200: {
                items: (mdDevice ? mdDevice : 4),
                nav: (mdDeviceNav ? true : false),
                dots: (mdDeviceDots ? true : false),
              }
            }
          });
        });

        // Skill bar
        var skillbar = $('.skillbar');
        if(skillbar.length) {
          $('.skillbar').skillBars({
            from: 0,
            speed: 4000,
            interval: 100,
            decimals: 0,
          });
        }

        // Counter Up
        var counter = $('.rs-count');
        if(counter.length) {
          $('.rs-count').counterUp({
            delay: 20,
            time: 1500
          });
        }

        // scrollTop init
        var totop = $('#scrollUp');
        win.on('scroll', function() {
          if (win.scrollTop() > 150) {
            totop.fadeIn();
          } else {
            totop.fadeOut();
          }
        });
        totop.on('click', function() {
          $("html,body").animate({
            scrollTop: 0
          }, 500)
        });

        //canvas menu
        var navexpander = $('#nav-expander');
        if(navexpander.length){
          $('#nav-expander, #nav-close, .offwrap').on('click',function(e){
            e.preventDefault();
            $('body').toggleClass('nav-expanded');
          });
        }

        // View Course
        $('.course-view-part .view-icons .view-grid').on('click',function(e){
          e.preventDefault();
          $('.rs-popular-courses').removeClass('list-view');
        });
        $('.course-view-part .view-icons .view-list').on('click',function(e){
          e.preventDefault();
          $('.rs-popular-courses').addClass('list-view');
        });


        /*----------------------------
          single-productjs active
          ------------------------------ */
        var singleproductimage = $('.single-product-image');
        if(singleproductimage.length){
          $('.single-product-image').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.single-product-nav'
          });
        }

        var singleproductnav = $('.single-product-nav');
        if(singleproductnav.length){
          $('.single-product-nav').slick({
            slidesToShow: 3,
            asNavFor: '.single-product-image',
            dots: false,
            focusOnSelect: true,
            centerMode:false,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2
                }
              },
              {
                breakpoint: 591,
                settings: {
                  slidesToShow: 2
                }
              }
            ]
          });
        }

        // categories btn
        $('.cat-menu-inner').hide();
        $('.cat-btn').on('click',function(){
          $('.cat-menu-inner').slideToggle();
        })

        var tilt = $('.js-tilt');
        if(tilt.length) {
          const tilt = $('.js-tilt').tilt();
        }
      });
    }
  }
</script>
