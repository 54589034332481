<template>
  <div id="service">
    <!-- Banner Section Start -->
    <div class="rs-banner style3 rs-rain-animate modify1">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 my-auto">
            <div class="banner-content">
              <!--<div class="rs-videos">
                <div class="animate-border white-color style3">
                  <a class="popup-border popup-videos" href="https://www.youtube.com/watch?v=YLN1Argi7ik">
                    <i class="fa fa-play"></i>
                  </a>
                </div>
              </div>-->
              <h1 class="title">System Development </h1>
              <p class="desc">
                Ready-made software no longer works with your business processes and if you're seeking a custom build
                solution, we're here for you. <br/>
                We provide world-class web or mobile system development services.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="images-part hidden-md">
        <img src="~@/assets/images/new/system-development/system-development-banner-test.jpg" class="round-corner" alt="">
      </div>
      <div class="line-inner style2">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
    <!-- Banner Section End -->

    <!-- Choose Section Start -->
    <div class="rs-why-choose rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-75">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 hidden-md">
            <div class="image-part">
              <img src="~@/assets/images/new/system-development/laravel-vue-banner.png" alt="">
            </div>
            <!--<div class="animation style2">
              <div class="top-shape">
                <img class="dance" src="~@/assets/images/choose/dotted-2.png" alt="images">
              </div>
              <div class="bottom-shape">
                <img class="dance2" src="~@/assets/images/choose/dotted-1.png" alt="images">
              </div>
            </div>-->
          </div>
          <div class="col-lg-6 pl-60 md-pl-15 my-auto">
            <div class="sec-title mb-40">
              <div class="sub-text style4-bg left">Why Choose Us</div>
              <h2 class="title pb-20">
                We are one of the Best Malaysia's Laravel Developer
              </h2>
              <div class="desc">
                We specializes in Laravel Web Development, we are able to deliver a wide range of custom web
                application solutions (mobile-ready) which includes, CRM, AR/AP, OLAP, Inventory system and more.
                All of our solutions are built with accordance to standard practices and future-maintainability in mind
                without compromising speed or security.
              </div>
              <img class="img-fluid laravel-logo d-md-block d-lg-none mt-3" src="~@/assets/images/new/tech/laravel.png">
            </div>
          </div>
        </div>
      </div>
      <div class="line-inner style2">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
    <!-- Choose Section End -->

    <!-- Services Section Start -->
    <div class="rs-services style3 rs-rain-animate gray-color pt-120 pb-120 md-pt-70 md-pb-80 custom-services">
      <div class="container">
        <div class="row md-mb-60">
          <div class="col-lg-12 mb-60 md-mb-20">
            <div class="sec-title2 md-center text-center">
              <span class="sub-text">Services</span>
              <h2 class="title testi-title">
                Our Custom Development Services
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 mb-20">
            <div class="services-item">
              <div class="row">
                <div class="col-lg-6 my-auto">
                  <div class="services-content">
                    <div class="services-text">
                      <h3 class="title"><a href="software-development.html">Custom Web Application</a></h3>
                    </div>
                    <div class="services-desc">
                      <p>
                        A web-based application accessible by all your devices. Your applications can be customized to work with all of your ever-changing business processes.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <img class="img-fluid service-img" src="~@/assets/images/new/system-development/custom-web-app.png">
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 mb-20">
            <div class="services-item">
              <div class="row">
                <div class="col-lg-6">
                  <img class="img-fluid service-img" src="~@/assets/images/new/system-development/maintain.png">
                </div>
                <div class="col-lg-6 my-auto">
                  <div class="services-content">
                    <div class="services-text">
                      <h3 class="title"><a href="software-development.html">Application Maintenance</a></h3>
                    </div>
                    <div class="services-desc">
                      <p>
                        We provide maintenance includes server maintenance, bug fixes, feature enhancements and consultation at an affordable retainer fee.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 mb-20">
            <div class="services-item">
              <div class="row">
                <div class="col-lg-6 my-auto">
                  <div class="services-content">
                    <div class="services-text">
                      <h3 class="title"><a href="software-development.html">API Server</a></h3>
                    </div>
                    <div class="services-desc">
                      <p>
                        We can custom make your own API server/backend services to serve your mobile applications, infrastructure or even just to connect between Google services.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <img class="img-fluid service-img" src="~@/assets/images/new/system-development/api-server.png">
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 mb-20">
            <div class="services-item">
              <div class="row">
                <div class="col-lg-6">
                  <img class="img-fluid service-img" src="~@/assets/images/new/system-development/mobile-app.png">
                </div>
                <div class="col-lg-6 my-auto">
                  <div class="services-content">
                    <div class="services-text">
                      <h3 class="title"><a href="software-development.html">Mobile Applications</a></h3>
                    </div>
                    <div class="services-desc">
                      <p>
                        Get your own custom mobile applications for your business, internal or external use, we can develop for both iOS and Andriod platforms, alongside its backend servers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line-inner">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
    <!-- Services Section End -->

    <contact-form></contact-form>

  </div>
</template>

<script>
  import ContactForm from "@/views/partials/ContactForm";
  export default {
    name: "SystemDevelopment",
    components: {ContactForm},
    mounted() {
      $(document).ready(function () {
        // sticky menu
        var header = $('.menu-sticky');
        var win = $(window);

        win.on('scroll', function() {
          var scroll = win.scrollTop();
          if (scroll < 1) {
            header.removeClass("sticky");
          } else {
            header.addClass("sticky");
          }

          $("section").each(function() {
            var elementTop = $(this).offset().top - $('#rs-header').outerHeight();
            if(scroll >= elementTop) {
              $(this).addClass('loaded');
            }
          });

        });

        //window load
        $(window).on( 'load', function() {
          $("#loading").delay(1500).fadeOut(500);
          $("#loading-center").on( 'click', function() {
            $("#loading").fadeOut(500);
          })
        })

        // Parallax Stuff
        if ($("#stuff").length) {
          var stuff = $('#stuff').get(0);
          var parallaxInstance = new Parallax(stuff);
        }

        // onepage nav
        var navclose = $('#onepage-menu');
        if(navclose.length){
          $(".nav-menu li a").on("click", function () {
            if ($(".showhide").is(":visible")) {
              $(".showhide").trigger("click");
            }
          });

          if ($.fn.onePageNav) {
            $(".nav-menu").onePageNav({
              currentClass: "current-menu-item"
            });
          }
        }

        //Testimonials Slider
        var sliderfor = $('.slider-for');
        if(sliderfor.length){
          $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav',
            autoplay: true
          });
        }
        var slidernav = $('.slider-nav');
        if(slidernav.length){
          $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            centerMode: true,
            focusOnSelect: true,
          });
        }

        // collapse hidden
        var navMain = $(".navbar-collapse");
        navMain.on("click", "a:not([data-toggle])", null, function () {
          navMain.collapse('hide');
        });

        // video
        if ($('.player').length) {
          $(".player").YTPlayer();
        }

        // wow init
        new WOW().init();

        // image loaded portfolio init
        var gridfilter = $('.grid');
        if(gridfilter.length){
          $('.grid').imagesLoaded(function() {
            $('.gridFilter').on('click', 'button', function() {
              var filterValue = $(this).attr('data-filter');
              $grid.isotope({
                filter: filterValue
              });
            });
            var $grid = $('.grid').isotope({
              itemSelector: '.grid-item',
              percentPosition: true,
              masonry: {
                columnWidth: '.grid-item',
              }
            });
          });
        }

        // project Filter
        if ($('.gridFilter button').length) {
          var projectfiler = $('.gridFilter button');
          if(projectfiler.length){
            $('.gridFilter button').on('click', function(event) {
              $(this).siblings('.active').removeClass('active');
              $(this).addClass('active');
              event.preventDefault();
            });
          }
        }

        // magnificPopup init
        var imagepopup = $('.image-popup');
        if(imagepopup.length){
          $('.image-popup').magnificPopup({
            type: 'image',
            callbacks: {
              beforeOpen: function() {
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure animated zoomInDown');
              }
            },
            gallery: {
              enabled: true
            }
          });
        }

        // Get a quote popup
        var popupquote = $('.popup-quote');
        if(popupquote.length){
          $('.popup-quote').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#qname',
            removalDelay: 500,
            callbacks: {
              beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
                if(win.width() < 800) {
                  this.st.focus = false;
                } else {
                  this.st.focus = '#qname';
                }
              }
            }
          });
        }

        //preloader
        $(window).on('load', function() {
          $("#loader").delay(1000).fadeOut(500);
        })

        //Videos popup jQuery
        var popupvideos = $('.popup-videos');
        if(popupvideos.length){
          $('.popup-videos').magnificPopup({
            disableOn: 10,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
          });
        }

        //CountDown Timer
        var CountTimer = $('.CountDownTimer');
        if(CountTimer.length){
          $(".CountDownTimer").TimeCircles({
            fg_width: 0.030,
            bg_width: 0.8,
            circle_bg_color: "#eeeeee",
            circle_fg_color: "#eeeeee",
            time: {
              Days:{
                color: "#032390"
              },
              Hours:{
                color: "#032390"
              },
              Minutes:{
                color: "#032390"
              },
              Seconds:{
                color: "#032390"
              }
            }
          });
        }

        var pieChart = $('.progress-pie-part');
        if(pieChart.length) {
          $('.chart').easyPieChart({
            size: 172,
            barColor: "#032390",
            scaleLength: 0,
            lineWidth: 8,
            trackColor: "#eeeeee",
            lineCap: "circle",
            animate: 2000,
          });
        }

        /*-------------------------------------
            OwlCarousel
        -------------------------------------*/
        $('.rs-carousel').each(function() {
          var owlCarousel = $(this),
              loop = owlCarousel.data('loop'),
              items = owlCarousel.data('items'),
              margin = owlCarousel.data('margin'),
              stagePadding = owlCarousel.data('stage-padding'),
              autoplay = owlCarousel.data('autoplay'),
              autoplayTimeout = owlCarousel.data('autoplay-timeout'),
              smartSpeed = owlCarousel.data('smart-speed'),
              dots = owlCarousel.data('dots'),
              nav = owlCarousel.data('nav'),
              navSpeed = owlCarousel.data('nav-speed'),
              xsDevice = owlCarousel.data('mobile-device'),
              xsDeviceNav = owlCarousel.data('mobile-device-nav'),
              xsDeviceDots = owlCarousel.data('mobile-device-dots'),
              smDevice = owlCarousel.data('ipad-device'),
              smDeviceNav = owlCarousel.data('ipad-device-nav'),
              smDeviceDots = owlCarousel.data('ipad-device-dots'),
              smDevice2 = owlCarousel.data('ipad-device2'),
              smDeviceNav2 = owlCarousel.data('ipad-device-nav2'),
              smDeviceDots2 = owlCarousel.data('ipad-device-dots2'),
              mdDevice = owlCarousel.data('md-device'),
              centerMode = owlCarousel.data('center-mode'),
              HoverPause = owlCarousel.data('hoverpause'),
              mdDeviceNav = owlCarousel.data('md-device-nav'),
              mdDeviceDots = owlCarousel.data('md-device-dots');
          owlCarousel.owlCarousel({
            loop: (loop ? true : false),
            items: (items ? items : 4),
            lazyLoad: true,
            center: (centerMode ? true : false),
            autoplayHoverPause: (HoverPause ? true : false),
            margin: (margin ? margin : 0),
            //stagePadding: (stagePadding ? stagePadding : 0),
            autoplay: (autoplay ? true : false),
            autoplayTimeout: (autoplayTimeout ? autoplayTimeout : 1000),
            smartSpeed: (smartSpeed ? smartSpeed : 250),
            dots: (dots ? true : false),
            nav: (nav ? true : false),
            navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
            navSpeed: (navSpeed ? true : false),
            responsiveClass: true,
            responsive: {
              0: {
                items: (xsDevice ? xsDevice : 1),
                nav: (xsDeviceNav ? true : false),
                dots: (xsDeviceDots ? true : false),
                center: false,
              },
              768: {
                items: (smDevice2 ? smDevice2 : 2),
                nav: (smDeviceNav2 ? true : false),
                dots: (smDeviceDots2 ? true : false),
                center: false,
              },
              992: {
                items: (smDevice ? smDevice : 3),
                nav: (smDeviceNav ? true : false),
                dots: (smDeviceDots ? true : false),
                center: false,
              },
              1200: {
                items: (mdDevice ? mdDevice : 4),
                nav: (mdDeviceNav ? true : false),
                dots: (mdDeviceDots ? true : false),
              }
            }
          });
        });

        // Skill bar
        var skillbar = $('.skillbar');
        if(skillbar.length) {
          $('.skillbar').skillBars({
            from: 0,
            speed: 4000,
            interval: 100,
            decimals: 0,
          });
        }

        // Counter Up
        var counter = $('.rs-count');
        if(counter.length) {
          $('.rs-count').counterUp({
            delay: 20,
            time: 1500
          });
        }

        // scrollTop init
        var totop = $('#scrollUp');
        win.on('scroll', function() {
          if (win.scrollTop() > 150) {
            totop.fadeIn();
          } else {
            totop.fadeOut();
          }
        });
        totop.on('click', function() {
          $("html,body").animate({
            scrollTop: 0
          }, 500)
        });

        //canvas menu
        var navexpander = $('#nav-expander');
        if(navexpander.length){
          $('#nav-expander, #nav-close, .offwrap').on('click',function(e){
            e.preventDefault();
            $('body').toggleClass('nav-expanded');
          });
        }

        // View Course
        $('.course-view-part .view-icons .view-grid').on('click',function(e){
          e.preventDefault();
          $('.rs-popular-courses').removeClass('list-view');
        });
        $('.course-view-part .view-icons .view-list').on('click',function(e){
          e.preventDefault();
          $('.rs-popular-courses').addClass('list-view');
        });


        /*----------------------------
          single-productjs active
          ------------------------------ */
        var singleproductimage = $('.single-product-image');
        if(singleproductimage.length){
          $('.single-product-image').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.single-product-nav'
          });
        }

        var singleproductnav = $('.single-product-nav');
        if(singleproductnav.length){
          $('.single-product-nav').slick({
            slidesToShow: 3,
            asNavFor: '.single-product-image',
            dots: false,
            focusOnSelect: true,
            centerMode:false,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2
                }
              },
              {
                breakpoint: 591,
                settings: {
                  slidesToShow: 2
                }
              }
            ]
          });
        }

        // categories btn
        $('.cat-menu-inner').hide();
        $('.cat-btn').on('click',function(){
          $('.cat-menu-inner').slideToggle();
        })

        var tilt = $('.js-tilt');
        if(tilt.length) {
          const tilt = $('.js-tilt').tilt();
        }
      });
    }
  }
</script>

<style scoped>

</style>
