<template>
    <div id="rs-contact" class="rs-contact gray-color pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="contact-box">
                        <h2 class="title white-color">Error!</h2>
                        <p class="white-color">Please try again!</p>
                        <small class="white-color">You will be redirect back to contact us page in 10 seconds!</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactUsFail",

        mounted() {
            setTimeout( ()=> this.$router.push({name: 'Contact'}), 10000);
        }
    }
</script>
